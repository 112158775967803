import React from "react";
import "./dateSelect.css";

const DateSelect = ({ options, selectName, controller, className, selected = null }) => {
  return (
    <select
      name={selectName}
      onChange={(e) => {
        controller(e.target.value);
      }}
      className= {`form-select select_box ${className}`}
      value={selected}
    >
      {options.map((option, key) => {
        return (
          <option key={key} className={option.className} value={option.value} >
            {option.name}
          </option>
        );
      })}
    </select>
  );
};

export default DateSelect;
